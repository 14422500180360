import { inject, Injectable } from '@angular/core';
import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';

@Injectable()
export class ClusteringService {
  private mapUIControls = inject(HereMapsUiControlsService);
  private mapUIStateService = inject(MapUiStateService);

  clusterIcons: any = {};
  markerIcons: any = {};

  currentClusterlayer: H.map.layer.ObjectLayer = {} as any;

  clusteredDataProvider: H.clustering.Provider;

  constructor() {
    this.genereateClusterIcons();
    this.generateMarkerIcons();
  }

  generateMarkerIcons() {
    const markers: string[] = ['nonmsp'];
    markers.forEach((marker) => {
      const svgUrl = `../assets/icons/mapIcons/markerIcons/${marker}.svg`;
      this.markerIcons[marker] = new H.map.Icon(svgUrl);
    });
  }

  genereateClusterIcons() {
    const clusterSizes = [
      2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000, 2000, 3000, 4000,
      5000,
    ];
    clusterSizes.forEach((size) => {
      const svgUrl = `../assets/icons/mapIcons/clusterIcons/${size}map-group.svg`;
      this.clusterIcons[size] = new H.map.Icon(svgUrl);
    });
  }

  getCorrectMarkerIcon(markerData: IChargePointv2): string {
    return 'nonmsp';
  }

  getClusterIconSize(count: number): number {
    if (count >= 5000) return 5000;
    if (count >= 4000) return 4000;
    if (count >= 3000) return 3000;
    if (count >= 2000) return 2000;
    if (count >= 1000) return 1000;
    if (count >= 500) return 500;
    if (count >= 400) return 400;
    if (count >= 300) return 300;
    if (count >= 200) return 200;
    if (count >= 100) return 100;
    if (count >= 90) return 90;
    if (count >= 80) return 80;
    if (count >= 70) return 70;
    if (count >= 60) return 60;
    if (count >= 50) return 50;
    if (count >= 40) return 40;
    if (count >= 30) return 30;
    if (count >= 20) return 20;
    if (count >= 15) return 15;
    if (count >= 10) return 10;
    if (count >= 9) return 9;
    if (count >= 8) return 8;
    if (count >= 7) return 7;
    if (count >= 6) return 6;
    if (count >= 5) return 5;
    if (count >= 4) return 4;
    if (count >= 3) return 3;
    return 2; // Default
  }
  setTheme(): H.clustering.ITheme {
    let Theme: H.clustering.ITheme = {
      getClusterPresentation: (cluster) => {
        const clusterSize = cluster.getWeight();
        var clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: this.clusterIcons[this.getClusterIconSize(clusterSize)],
          data: cluster,
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });

        //need to Implement click logic here i guess
        return clusterMarker;
      },
      getNoisePresentation: (noisePoint: H.clustering.INoisePoint): H.map.Object => {
        let data = noisePoint.getData() as IChargePointv2;

        const markerIcon = this.getCorrectMarkerIcon(data);

        let noiseMarker = new H.map.Marker(
          noisePoint.getPosition(),
          {
            icon: this.markerIcons[markerIcon],
            data: data,
            min: noisePoint.getMinZoom(),
          },
          // Use min zoom from a noise point
          // to show it correctly at certain zoom levels:
        );
        noiseMarker.setData(data);

        return noiseMarker;
      },
    };

    return Theme;
  }

  removeClustering() {
    if (this.currentClusterlayer) {
      this.mapUIControls.mapInstance.removeLayer(this.currentClusterlayer);
    }
  }

  createCluster(chargePoints: IChargePointv2[]) {
    // remove previous clusters
    this.clusteredDataProvider?.removeEventListener('tap', () => {});
    this.removeClustering();
    let t1 = performance.now();
    let dataPoints: H.clustering.DataPoint[] = chargePoints.map((chargePoint) => {
      return new H.clustering.DataPoint(
        chargePoint.coordinates.latitude,
        chargePoint.coordinates.longitude,
        1,
        chargePoint,
      );
    });

    this.clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        minWeight: 2,
        strategy: H.clustering.Provider.Strategy.FASTGRID,
        eps: 32,
      },
      theme: this.setTheme(),
    });

    let layer = new H.map.layer.ObjectLayer(this.clusteredDataProvider);

    //Clustering markers visible zoom
    // we dont need this if we decide to use clustering only
    // layer.setMax(9);

    this.currentClusterlayer = layer;

    this.mapUIControls.mapInstance.addLayer(layer);
    this.mapUIControls.clusterLayer = layer;
    this.clusteredDataProvider.setDataPoints(dataPoints);
    let t2 = performance.now();
    this.mapUIControls.reCreateCluster = false;

    this.attachClusterTapListener();
  }

  attachClusterTapListener() {
    this.clusteredDataProvider.addEventListener(
      'tap',
      (event: H.mapevents.Event) => {
        // Get the clicked marker - either a cluster or IChargePointv2
        const marker = event.target as H.map.Object;

        const point = marker.getData() as H.clustering.ICluster | IChargePointv2;
        if ('isCluster' in point && point.isCluster()) {
          // It's a cluster
          const cluster = point as H.clustering.ICluster;
          // probably zoom in until next cluster formation
          point.forEachDataPoint((dataPoint: H.clustering.INoisePoint) => {
            const position = dataPoint.getPosition();
          });
        } else {
          // It's an IChargePointv2  type load load charge point details
          const chargePoint = point as IChargePointv2;
          this.mapUIStateService.selectedChargePoint.set(chargePoint);
          this.mapUIStateService.showChargePointDetails.set(true);
          this.mapUIStateService.hideFilterMenu();

          this.mapUIControls.navigateToPoint(
            {
              lat: chargePoint.coordinates.latitude,
              lng: chargePoint.coordinates.longitude,
            },
            16,
          );
        }
      },
      false,
    );
  }
}
