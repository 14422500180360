import { HttpClient, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutoComplete, LookUp } from '@pages/map/interfaces/HEREAPI';

@Injectable()
export class HereApiService {
  apikey = 'wyBnr85mHwleRwtLqvj5fwZ9_g9JnCovZMinW7FxHhs'; // where to store it
  httpClient = inject(HttpClient);
  jsonP = inject(JsonpClientBackend);

  private readonly autoCompleteURL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
  private readonly lookupURL = 'https://lookup.search.hereapi.com/v1/lookup';

  constructor() {}

  getAutoCompleteParams(searchText: string): HttpParams {
    return new HttpParams().set('apiKey', this.apikey).set('q', searchText);
  }

  getAutocompleteResults(searchText: string): Observable<AutoComplete> {
    return this.httpClient.jsonp(
      this.autoCompleteURL + '?' + this.getAutoCompleteParams(searchText).toString(),
      'callback',
    ) as any;
  }

  getLookUpParams(id: string) {
    return new HttpParams().set('apiKey', this.apikey).set('id', id);
  }

  getLookUResults(id: string): Observable<LookUp> {
    return this.httpClient.jsonp(this.lookupURL + '?' + this.getLookUpParams(id).toString(), 'callback') as any;
  }
}
