export const truck_charging = `<svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_1939_51269)">
<ellipse cx="23" cy="48.5" rx="13" ry="3.5" fill="black" fill-opacity="0.2"/>
</g>
<path d="M39.75 18.5C39.75 19.6113 39.4451 21.0832 38.9795 22.535C38.5151 23.9831 37.8987 25.3871 37.2899 26.3645L23.124 47.6132C22.8272 48.0585 22.1728 48.0585 21.876 47.6133L7.71013 26.3645C7.10133 25.3871 6.48487 23.9831 6.02048 22.535C5.5549 21.0832 5.25 19.6113 5.25 18.5C5.25 8.97303 12.9731 1.25 22.5 1.25C32.0269 1.25 39.75 8.97303 39.75 18.5Z" fill="#16417F" stroke="#0F3263" stroke-width="0.5"/>
<circle cx="22.5" cy="18.5" r="13.5" fill="#16417F"/>
<g clip-path="url(#clip0_1939_51269)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4062 13.5H28.5V14.1875V17.625V18.3125H25.4062C24.0774 18.3125 23 17.2351 23 15.9062C23 14.5773 24.0774 13.5 25.4062 13.5ZM29.875 16.9375V14.875H31.9375C32.3172 14.875 32.625 14.5672 32.625 14.1875C32.625 13.8078 32.3172 13.5 31.9375 13.5H29.875V12.8125C29.875 12.4328 29.5672 12.125 29.1875 12.125H25.4062C23.6754 12.125 22.2163 13.2879 21.7674 14.875H16.8125C15.9008 14.875 15.0265 15.2372 14.3818 15.8818C13.7372 16.5265 13.375 17.4008 13.375 18.3125C13.375 19.2242 13.7372 20.0985 14.3818 20.7432C15.0265 21.3878 15.9008 21.75 16.8125 21.75H20.9375C21.3022 21.75 21.652 21.8948 21.9098 22.1527C22.1677 22.4106 22.3125 22.7604 22.3125 23.125C22.3125 23.4896 22.1677 23.8394 21.9098 24.0973C21.652 24.3552 21.3022 24.5 20.9375 24.5C20.9371 24.5 20.9368 24.5 20.9364 24.5H15.4375C15.0578 24.5 14.75 24.8078 14.75 25.1875C14.75 25.5672 15.0578 25.875 15.4375 25.875H20.9375C20.9382 25.875 20.939 25.875 20.9397 25.875C21.6683 25.8745 22.3669 25.5848 22.882 25.0695C23.3977 24.5538 23.6875 23.8543 23.6875 23.125C23.6875 22.3957 23.3977 21.6962 22.882 21.1805C22.3663 20.6648 21.6668 20.375 20.9375 20.375H16.8125C16.2655 20.375 15.7409 20.1577 15.3541 19.7709C14.9673 19.3841 14.75 18.8595 14.75 18.3125C14.75 17.7655 14.9673 17.2409 15.3541 16.8541C15.7409 16.4673 16.2655 16.25 16.8125 16.25H21.6404C21.8141 18.1772 23.4338 19.6875 25.4062 19.6875H29.1875C29.5672 19.6875 29.875 19.3797 29.875 19V18.3125H31.9375C32.3172 18.3125 32.625 18.0047 32.625 17.625C32.625 17.2453 32.3172 16.9375 31.9375 16.9375H29.875Z" fill="white" fill-opacity="0.87"/>
</g>
<defs>
<filter id="filter0_f_1939_51269" x="6" y="41" width="34" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1939_51269"/>
</filter>
<clipPath id="clip0_1939_51269">
<rect width="22" height="22" fill="white" transform="translate(12 8)"/>
</clipPath>
</defs>
</svg>
`;

export const truck_charging_hover = `<svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_1939_51192)">
<ellipse cx="23" cy="48.5" rx="13" ry="3.5" fill="black" fill-opacity="0.2"/>
</g>
<path d="M39.75 18.5C39.75 19.6113 39.4451 21.0832 38.9795 22.535C38.5151 23.9831 37.8987 25.3871 37.2899 26.3645L23.124 47.6133C22.8272 48.0585 22.1728 48.0585 21.876 47.6133L7.71013 26.3645C7.10133 25.3871 6.48487 23.9831 6.02048 22.535C5.5549 21.0832 5.25 19.6113 5.25 18.5C5.25 8.97303 12.9731 1.25 22.5 1.25C32.0269 1.25 39.75 8.97303 39.75 18.5Z" fill="#16417F" stroke="#0F3263" stroke-width="0.5"/>
<circle cx="22.5" cy="18.5" r="13.5" fill="#F9FAFB"/>
<g clip-path="url(#clip0_1939_51192)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4062 13.5H28.5V14.1875V17.625V18.3125H25.4062C24.0774 18.3125 23 17.2351 23 15.9062C23 14.5773 24.0774 13.5 25.4062 13.5ZM29.875 16.9375V14.875H31.9375C32.3172 14.875 32.625 14.5672 32.625 14.1875C32.625 13.8078 32.3172 13.5 31.9375 13.5H29.875V12.8125C29.875 12.4328 29.5672 12.125 29.1875 12.125H25.4062C23.6754 12.125 22.2163 13.2879 21.7674 14.875H16.8125C15.9008 14.875 15.0265 15.2372 14.3818 15.8818C13.7372 16.5265 13.375 17.4008 13.375 18.3125C13.375 19.2242 13.7372 20.0985 14.3818 20.7432C15.0265 21.3878 15.9008 21.75 16.8125 21.75H20.9375C21.3022 21.75 21.652 21.8948 21.9098 22.1527C22.1677 22.4106 22.3125 22.7604 22.3125 23.125C22.3125 23.4896 22.1677 23.8394 21.9098 24.0973C21.652 24.3552 21.3022 24.5 20.9375 24.5C20.9371 24.5 20.9368 24.5 20.9364 24.5H15.4375C15.0578 24.5 14.75 24.8078 14.75 25.1875C14.75 25.5672 15.0578 25.875 15.4375 25.875H20.9375C20.9382 25.875 20.939 25.875 20.9397 25.875C21.6683 25.8745 22.3669 25.5848 22.882 25.0695C23.3977 24.5538 23.6875 23.8543 23.6875 23.125C23.6875 22.3957 23.3977 21.6962 22.882 21.1805C22.3663 20.6648 21.6668 20.375 20.9375 20.375H16.8125C16.2655 20.375 15.7409 20.1577 15.3541 19.7709C14.9673 19.3841 14.75 18.8595 14.75 18.3125C14.75 17.7655 14.9673 17.2409 15.3541 16.8541C15.7409 16.4673 16.2655 16.25 16.8125 16.25H21.6404C21.8141 18.1772 23.4338 19.6875 25.4062 19.6875H29.1875C29.5672 19.6875 29.875 19.3797 29.875 19V18.3125H31.9375C32.3172 18.3125 32.625 18.0047 32.625 17.625C32.625 17.2453 32.3172 16.9375 31.9375 16.9375H29.875Z" fill="black" fill-opacity="0.87"/>
</g>
<defs>
<filter id="filter0_f_1939_51192" x="6" y="41" width="34" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1939_51192"/>
</filter>
<clipPath id="clip0_1939_51192">
<rect width="22" height="22" fill="white" transform="translate(12 8)"/>
</clipPath>
</defs>
</svg>
`;

export const unspecified_charging = `<svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_1939_51279)">
<ellipse cx="23" cy="48.5" rx="13" ry="3.5" fill="black" fill-opacity="0.2"/>
</g>
<path d="M39.75 18.5C39.75 19.6113 39.4451 21.0832 38.9795 22.535C38.5151 23.9831 37.8987 25.3871 37.2899 26.3645L23.124 47.6132C22.8272 48.0585 22.1728 48.0585 21.876 47.6133L7.71013 26.3645C7.10133 25.3871 6.48487 23.9831 6.02048 22.535C5.5549 21.0832 5.25 19.6113 5.25 18.5C5.25 8.97303 12.9731 1.25 22.5 1.25C32.0269 1.25 39.75 8.97303 39.75 18.5Z" fill="#56657A" stroke="#37414F" stroke-width="0.5"/>
<circle cx="22.5" cy="18.5" r="13.5" fill="#56657A"/>
<g clip-path="url(#clip0_1939_51279)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4062 13.5H28.5V14.1875V17.625V18.3125H25.4062C24.0774 18.3125 23 17.2351 23 15.9062C23 14.5773 24.0774 13.5 25.4062 13.5ZM29.875 16.9375V14.875H31.9375C32.3172 14.875 32.625 14.5672 32.625 14.1875C32.625 13.8078 32.3172 13.5 31.9375 13.5H29.875V12.8125C29.875 12.4328 29.5672 12.125 29.1875 12.125H25.4062C23.6754 12.125 22.2163 13.2879 21.7674 14.875H16.8125C15.9008 14.875 15.0265 15.2372 14.3818 15.8818C13.7372 16.5265 13.375 17.4008 13.375 18.3125C13.375 19.2242 13.7372 20.0985 14.3818 20.7432C15.0265 21.3878 15.9008 21.75 16.8125 21.75H20.9375C21.3022 21.75 21.652 21.8948 21.9098 22.1527C22.1677 22.4106 22.3125 22.7604 22.3125 23.125C22.3125 23.4896 22.1677 23.8394 21.9098 24.0973C21.652 24.3552 21.3022 24.5 20.9375 24.5C20.9371 24.5 20.9368 24.5 20.9364 24.5H15.4375C15.0578 24.5 14.75 24.8078 14.75 25.1875C14.75 25.5672 15.0578 25.875 15.4375 25.875H20.9375C20.9382 25.875 20.939 25.875 20.9397 25.875C21.6683 25.8745 22.3669 25.5848 22.882 25.0695C23.3977 24.5538 23.6875 23.8543 23.6875 23.125C23.6875 22.3957 23.3977 21.6962 22.882 21.1805C22.3663 20.6648 21.6668 20.375 20.9375 20.375H16.8125C16.2655 20.375 15.7409 20.1577 15.3541 19.7709C14.9673 19.3841 14.75 18.8595 14.75 18.3125C14.75 17.7655 14.9673 17.2409 15.3541 16.8541C15.7409 16.4673 16.2655 16.25 16.8125 16.25H21.6404C21.8141 18.1772 23.4338 19.6875 25.4062 19.6875H29.1875C29.5672 19.6875 29.875 19.3797 29.875 19V18.3125H31.9375C32.3172 18.3125 32.625 18.0047 32.625 17.625C32.625 17.2453 32.3172 16.9375 31.9375 16.9375H29.875Z" fill="white" fill-opacity="0.87"/>
</g>
<defs>
<filter id="filter0_f_1939_51279" x="6" y="41" width="34" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1939_51279"/>
</filter>
<clipPath id="clip0_1939_51279">
<rect width="22" height="22" fill="white" transform="translate(12 8)"/>
</clipPath>
</defs>
</svg>
`;

export const unspecified_charging_hover = `<svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_1939_51289)">
<ellipse cx="23" cy="48.5" rx="13" ry="3.5" fill="black" fill-opacity="0.2"/>
</g>
<path d="M39.75 18.5C39.75 19.6113 39.4451 21.0832 38.9795 22.535C38.5151 23.9831 37.8987 25.3871 37.2899 26.3645L23.124 47.6132C22.8272 48.0585 22.1728 48.0585 21.876 47.6133L7.71013 26.3645C7.10133 25.3871 6.48487 23.9831 6.02048 22.535C5.5549 21.0832 5.25 19.6113 5.25 18.5C5.25 8.97303 12.9731 1.25 22.5 1.25C32.0269 1.25 39.75 8.97303 39.75 18.5Z" fill="#56657A" stroke="#37414F" stroke-width="0.5"/>
<circle cx="22.5" cy="18.5" r="13.5" fill="#F9FAFB"/>
<g clip-path="url(#clip0_1939_51289)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4062 13.5H28.5V14.1875V17.625V18.3125H25.4062C24.0774 18.3125 23 17.2351 23 15.9062C23 14.5773 24.0774 13.5 25.4062 13.5ZM29.875 16.9375V14.875H31.9375C32.3172 14.875 32.625 14.5672 32.625 14.1875C32.625 13.8078 32.3172 13.5 31.9375 13.5H29.875V12.8125C29.875 12.4328 29.5672 12.125 29.1875 12.125H25.4062C23.6754 12.125 22.2163 13.2879 21.7674 14.875H16.8125C15.9008 14.875 15.0265 15.2372 14.3818 15.8818C13.7372 16.5265 13.375 17.4008 13.375 18.3125C13.375 19.2242 13.7372 20.0985 14.3818 20.7432C15.0265 21.3878 15.9008 21.75 16.8125 21.75H20.9375C21.3022 21.75 21.652 21.8948 21.9098 22.1527C22.1677 22.4106 22.3125 22.7604 22.3125 23.125C22.3125 23.4896 22.1677 23.8394 21.9098 24.0973C21.652 24.3552 21.3022 24.5 20.9375 24.5C20.9371 24.5 20.9368 24.5 20.9364 24.5H15.4375C15.0578 24.5 14.75 24.8078 14.75 25.1875C14.75 25.5672 15.0578 25.875 15.4375 25.875H20.9375C20.9382 25.875 20.939 25.875 20.9397 25.875C21.6683 25.8745 22.3669 25.5848 22.882 25.0695C23.3977 24.5538 23.6875 23.8543 23.6875 23.125C23.6875 22.3957 23.3977 21.6962 22.882 21.1805C22.3663 20.6648 21.6668 20.375 20.9375 20.375H16.8125C16.2655 20.375 15.7409 20.1577 15.3541 19.7709C14.9673 19.3841 14.75 18.8595 14.75 18.3125C14.75 17.7655 14.9673 17.2409 15.3541 16.8541C15.7409 16.4673 16.2655 16.25 16.8125 16.25H21.6404C21.8141 18.1772 23.4338 19.6875 25.4062 19.6875H29.1875C29.5672 19.6875 29.875 19.3797 29.875 19V18.3125H31.9375C32.3172 18.3125 32.625 18.0047 32.625 17.625C32.625 17.2453 32.3172 16.9375 31.9375 16.9375H29.875Z" fill="black" fill-opacity="0.87"/>
</g>
<defs>
<filter id="filter0_f_1939_51289" x="6" y="41" width="34" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1939_51289"/>
</filter>
<clipPath id="clip0_1939_51289">
<rect width="22" height="22" fill="white" transform="translate(12 8)"/>
</clipPath>
</defs>
</svg>
`;

export const markerSVG = `<svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3373 35.7838C14.3373 35.7838 14.3381 35.7843 15.055 34.7156L14.3381 35.7843C14.7722 36.0719 15.3378 36.0719 15.7719 35.7843L15.055 34.7156C15.7719 35.7843 15.7727 35.7838 15.7727 35.7838L15.7737 35.7831L15.7767 35.7811L15.7865 35.7746L15.8207 35.7516C15.8499 35.7319 15.8918 35.7034 15.9455 35.6665C16.0529 35.5925 16.2077 35.4845 16.4031 35.3443C16.7939 35.0639 17.3477 34.654 18.0106 34.1295C19.334 33.0822 21.1021 31.5697 22.8746 29.7105C26.3666 26.0475 30.11 20.7957 30.11 14.9608C30.11 10.993 28.5239 7.18763 25.7005 4.38192C22.8771 1.57622 19.0478 0 15.055 0C11.0622 0 7.23288 1.57622 4.4095 4.38192C1.58615 7.18763 0 10.993 0 14.9608C0 20.7957 3.74341 26.0475 7.23543 29.7105C9.00793 31.5697 10.776 33.0822 12.0994 34.1295C12.7623 34.654 13.3161 35.0639 13.7069 35.3443C13.9023 35.4845 14.0571 35.5925 14.1645 35.6665C14.2182 35.7034 14.2601 35.7319 14.2893 35.7516L14.3235 35.7746L14.3333 35.7811L14.3363 35.7831L14.3373 35.7838ZM24.3782 14.2923C24.3782 19.4617 20.204 23.6522 15.0549 23.6522C9.9058 23.6522 5.73161 19.4617 5.73161 14.2923C5.73161 9.12306 9.9058 4.9325 15.0549 4.9325C20.204 4.9325 24.3782 9.12306 24.3782 14.2923Z" fill="#0F3263"/>
    <path d="M15.055 27.4909C21.9236 27.4909 27.4918 21.9229 27.4918 15.0545C27.4918 8.18611 21.9236 2.61816 15.055 2.61816C8.1864 2.61816 2.61829 8.18611 2.61829 15.0545C2.61829 21.9229 8.1864 27.4909 15.055 27.4909Z" fill="#0F3263"/>
    <mask id="mask0_758_36390" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16">
        <path d="M22.9098 7.2002H7.2002V22.9093H22.9098V7.2002Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_758_36390)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7732 11.1273H18.9823V11.6182V14.0728V14.5637H16.7732C15.8243 14.5637 15.0549 13.7944 15.0549 12.8455C15.0549 11.8966 15.8243 11.1273 16.7732 11.1273ZM19.9642 13.5819V12.1091H21.437C21.7081 12.1091 21.9279 11.8893 21.9279 11.6182C21.9279 11.3471 21.7081 11.1273 21.437 11.1273H19.9642V10.6364C19.9642 10.3653 19.7444 10.1455 19.4733 10.1455H16.7732C15.5372 10.1455 14.4953 10.9759 14.1747 12.1091H10.6366C9.9856 12.1091 9.36127 12.3678 8.90098 12.828C8.44063 13.2884 8.18201 13.9127 8.18201 14.5637C8.18201 15.2147 8.44063 15.839 8.90098 16.2993C9.36127 16.7596 9.9856 17.0182 10.6366 17.0182H13.5822C13.8426 17.0182 14.0923 17.1217 14.2765 17.3058C14.4606 17.4899 14.564 17.7397 14.564 18.0001C14.564 18.2604 14.4606 18.5102 14.2765 18.6943C14.0923 18.8785 13.8426 18.9819 13.5822 18.9819H13.5814H9.65478C9.38366 18.9819 9.16385 19.2017 9.16385 19.4728C9.16385 19.7439 9.38366 19.9637 9.65478 19.9637H13.5822H13.5837C14.104 19.9633 14.6028 19.7565 14.9707 19.3885C15.339 19.0203 15.5459 18.5208 15.5459 18.0001C15.5459 17.4793 15.339 16.9798 14.9707 16.6116C14.6024 16.2433 14.1029 16.0364 13.5822 16.0364H10.6366C10.246 16.0364 9.87144 15.8812 9.59521 15.6051C9.31899 15.3289 9.16385 14.9543 9.16385 14.5637C9.16385 14.1731 9.31899 13.7985 9.59521 13.5223C9.87144 13.2461 10.246 13.091 10.6366 13.091H14.0841C14.2081 14.4671 15.3647 15.5455 16.7732 15.5455H19.4733C19.7444 15.5455 19.9642 15.3257 19.9642 15.0546V14.5637H21.437C21.7081 14.5637 21.9279 14.3439 21.9279 14.0728C21.9279 13.8017 21.7081 13.5819 21.437 13.5819H19.9642Z" fill="white"/>
    </g>
</svg>
`;

export const HiglightedMarkerSVG = `<svg width="46" height="55" viewBox="0 0 46 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.9036 54.6697C21.9036 54.6697 21.9047 54.6704 23 53.0377L21.9047 54.6704C22.5679 55.1099 23.4321 55.1099 24.0953 54.6704L23 53.0377C24.0953 54.6704 24.0964 54.6697 24.0964 54.6697L24.098 54.6686L24.1026 54.6656L24.1176 54.6556L24.1698 54.6205C24.2144 54.5904 24.2784 54.5469 24.3604 54.4905C24.5245 54.3775 24.761 54.2125 25.0596 53.9982C25.6565 53.5698 26.5027 52.9436 27.5153 52.1423C29.5372 50.5423 32.2383 48.2315 34.9462 45.391C40.2811 39.7948 46 31.7712 46 22.8568C46 16.7948 43.5768 10.9811 39.2635 6.6946C34.9501 2.40812 29.1 0 23 0C16.9 0 11.0499 2.40812 6.73654 6.6946C2.42321 10.9811 0 16.7948 0 22.8568C0 31.7712 5.71892 39.7948 11.0538 45.391C13.7617 48.2315 16.4628 50.5423 18.4847 52.1423C19.4973 52.9436 20.3435 53.5698 20.9404 53.9982C21.239 54.2125 21.4755 54.3775 21.6396 54.4905C21.7216 54.5469 21.7856 54.5904 21.8302 54.6205L21.8824 54.6556L21.8974 54.6656L21.902 54.6686L21.9036 54.6697ZM37.2438 21.8349C37.2438 29.7324 30.8668 36.1347 23.0003 36.1347C15.1339 36.1347 8.75684 29.7324 8.75684 21.8349C8.75684 13.9374 15.1339 7.53516 23.0003 7.53516C30.8668 7.53516 37.2438 13.9374 37.2438 21.8349Z" fill="#16417F"/>
<circle cx="23" cy="23" r="19" fill="#F9FAFB"/>
<g clip-path="url(#clip0_1520_14072)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.625 17H29V17.75V21.5V22.25H25.625C24.1753 22.25 23 21.0747 23 19.625C23 18.1753 24.1753 17 25.625 17ZM30.5 20.75V18.5H32.75C33.1642 18.5 33.5 18.1642 33.5 17.75C33.5 17.3358 33.1642 17 32.75 17H30.5V16.25C30.5 15.8358 30.1642 15.5 29.75 15.5H25.625C23.7368 15.5 22.145 16.7686 21.6553 18.5H16.25C15.2554 18.5 14.3016 18.8951 13.5984 19.5983C12.8951 20.3016 12.5 21.2554 12.5 22.25C12.5 23.2446 12.8951 24.1984 13.5984 24.9017C14.3016 25.6049 15.2554 26 16.25 26H20.75C21.1478 26 21.5294 26.158 21.8107 26.4393C22.092 26.7206 22.25 27.1022 22.25 27.5C22.25 27.8978 22.092 28.2794 21.8107 28.5607C21.5294 28.842 21.1478 29 20.75 29C20.7496 29 20.7492 29 20.7488 29H14.75C14.3358 29 14 29.3358 14 29.75C14 30.1642 14.3358 30.5 14.75 30.5H20.75C20.7508 30.5 20.7516 30.5 20.7524 30.5C21.5472 30.4994 22.3093 30.1834 22.8713 29.6213C23.4339 29.0587 23.75 28.2956 23.75 27.5C23.75 26.7044 23.4339 25.9413 22.8713 25.3787C22.3087 24.8161 21.5456 24.5 20.75 24.5H16.25C15.6533 24.5 15.081 24.2629 14.659 23.841C14.237 23.419 14 22.8467 14 22.25C14 21.6533 14.237 21.081 14.659 20.659C15.081 20.2371 15.6533 20 16.25 20H21.5168C21.7063 22.1024 23.4732 23.75 25.625 23.75H29.75C30.1642 23.75 30.5 23.4142 30.5 23V22.25H32.75C33.1642 22.25 33.5 21.9142 33.5 21.5C33.5 21.0858 33.1642 20.75 32.75 20.75H30.5Z" fill="black" fill-opacity="0.87"/>
</g>
<defs>
<clipPath id="clip0_1520_14072">
<rect width="24" height="24" fill="white" transform="translate(11 11)"/>
</clipPath>
</defs>
</svg>
`;

export const defaultMarker = `<svg width="46" height="55" viewBox="0 0 46 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.9036 54.6697C21.9036 54.6697 21.9047 54.6704 23 53.0377L21.9047 54.6704C22.5679 55.1099 23.4321 55.1099 24.0953 54.6704L23 53.0377C24.0953 54.6704 24.0964 54.6697 24.0964 54.6697L24.098 54.6686L24.1026 54.6656L24.1176 54.6556L24.1698 54.6205C24.2144 54.5904 24.2784 54.5469 24.3604 54.4905C24.5245 54.3775 24.761 54.2125 25.0596 53.9982C25.6565 53.5698 26.5027 52.9436 27.5153 52.1423C29.5372 50.5423 32.2383 48.2315 34.9462 45.391C40.2811 39.7948 46 31.7712 46 22.8568C46 16.7948 43.5768 10.9811 39.2635 6.6946C34.9501 2.40812 29.1 0 23 0C16.9 0 11.0499 2.40812 6.73654 6.6946C2.42321 10.9811 0 16.7948 0 22.8568C0 31.7712 5.71892 39.7948 11.0538 45.391C13.7617 48.2315 16.4628 50.5423 18.4847 52.1423C19.4973 52.9436 20.3435 53.5698 20.9404 53.9982C21.239 54.2125 21.4755 54.3775 21.6396 54.4905C21.7216 54.5469 21.7856 54.5904 21.8302 54.6205L21.8824 54.6556L21.8974 54.6656L21.902 54.6686L21.9036 54.6697ZM37.2434 21.8355C37.2434 29.7331 30.8663 36.1353 22.9999 36.1353C15.1334 36.1353 8.75636 29.7331 8.75636 21.8355C8.75636 13.938 15.1334 7.53577 22.9999 7.53577C30.8663 7.53577 37.2434 13.938 37.2434 21.8355Z" fill="#16417F"/>
<circle cx="23" cy="23" r="19" fill="#16417F"/>
<g clip-path="url(#clip0_1520_14094)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.625 17H29V17.75V21.5V22.25H25.625C24.1753 22.25 23 21.0747 23 19.625C23 18.1753 24.1753 17 25.625 17ZM30.5 20.75V18.5H32.75C33.1642 18.5 33.5 18.1642 33.5 17.75C33.5 17.3358 33.1642 17 32.75 17H30.5V16.25C30.5 15.8358 30.1642 15.5 29.75 15.5H25.625C23.7368 15.5 22.145 16.7686 21.6553 18.5H16.25C15.2554 18.5 14.3016 18.8951 13.5984 19.5983C12.8951 20.3016 12.5 21.2554 12.5 22.25C12.5 23.2446 12.8951 24.1984 13.5984 24.9017C14.3016 25.6049 15.2554 26 16.25 26H20.75C21.1478 26 21.5294 26.158 21.8107 26.4393C22.092 26.7206 22.25 27.1022 22.25 27.5C22.25 27.8978 22.092 28.2794 21.8107 28.5607C21.5294 28.842 21.1478 29 20.75 29C20.7496 29 20.7492 29 20.7488 29H14.75C14.3358 29 14 29.3358 14 29.75C14 30.1642 14.3358 30.5 14.75 30.5H20.75C20.7508 30.5 20.7516 30.5 20.7524 30.5C21.5472 30.4994 22.3093 30.1834 22.8713 29.6213C23.4339 29.0587 23.75 28.2956 23.75 27.5C23.75 26.7044 23.4339 25.9413 22.8713 25.3787C22.3087 24.8161 21.5456 24.5 20.75 24.5H16.25C15.6533 24.5 15.081 24.2629 14.659 23.841C14.237 23.419 14 22.8467 14 22.25C14 21.6533 14.237 21.081 14.659 20.659C15.081 20.2371 15.6533 20 16.25 20H21.5168C21.7063 22.1024 23.4732 23.75 25.625 23.75H29.75C30.1642 23.75 30.5 23.4142 30.5 23V22.25H32.75C33.1642 22.25 33.5 21.9142 33.5 21.5C33.5 21.0858 33.1642 20.75 32.75 20.75H30.5Z" fill="white" fill-opacity="0.87"/>
</g>
<defs>
<clipPath id="clip0_1520_14094">
<rect width="24" height="24" fill="white" transform="translate(11 11)"/>
</clipPath>
</defs>
</svg>
`;

export const customLocationPinSVG = `<svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9989 -0.000976562C8.93861 -0.000976562 6.00362 1.21473 3.83965 3.37871C1.67567 5.54269 0.459961 8.47767 0.459961 11.538C0.459961 16.0242 3.34614 20.2018 6.25032 23.2674C7.70052 24.7981 9.15102 26.0472 10.2391 26.9136C10.705 27.2847 11.1044 27.5854 11.4085 27.8079C11.7601 28.0651 12.2378 28.0651 12.5893 27.8079C12.8935 27.5854 13.2928 27.2847 13.7588 26.9136C14.8468 26.0472 16.2974 24.7981 17.7475 23.2674C20.6517 20.2018 23.5379 16.0242 23.5379 11.538C23.5379 8.47767 22.3222 5.54269 20.1582 3.37871C17.9942 1.21473 15.0593 -0.000976562 11.9989 -0.000976562ZM5.25386 4.79292C7.04277 3.00402 9.46904 1.99902 11.9989 1.99902C14.5288 1.99902 16.9551 3.00402 18.744 4.79292C20.5329 6.58183 21.5379 9.0081 21.5379 11.538C21.5379 15.2203 19.1202 18.9104 16.2956 21.8919C14.9261 23.3375 13.5495 24.5236 12.5129 25.3491C12.3298 25.4949 12.1578 25.629 11.9989 25.7509C11.8401 25.629 11.6681 25.4949 11.4849 25.3491C10.4484 24.5236 9.07174 23.3375 7.70223 21.8919C4.87765 18.9104 2.45996 15.2203 2.45996 11.538C2.45996 9.0081 3.46496 6.58183 5.25386 4.79292Z" fill="#0D0F13"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9985 5.9953C8.97224 5.99558 6.51904 8.44895 6.51904 11.4753C6.51904 14.5018 8.97252 16.9553 11.999 16.9553C15.0253 16.955 17.4785 14.5016 17.4785 11.4753C17.4785 8.44874 15.025 5.9953 11.9985 5.9953ZM15.4785 11.4753C15.4785 13.3972 13.9205 14.9553 11.9985 14.9553C10.0768 14.955 8.51904 13.3971 8.51904 11.4753C8.51904 9.55335 10.0771 7.9953 11.999 7.9953C13.9208 7.99559 15.4785 9.55349 15.4785 11.4753Z" fill="#0D0F13"/>
</svg>`;

export const partner_icon = `<svg width="119" height="130" viewBox="0 0 119 130" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1508_16628)">
<path d="M84.9628 58.4814C84.9628 72.5544 65.8518 87.2996 59.4814 93.67C53.0945 87.2831 34 72.5544 34 58.4814C34 44.4084 45.4084 33 59.4814 33C73.5544 33 84.9628 44.4084 84.9628 58.4814Z" fill="#2058A8"/>
<path d="M58.7743 94.3771L59.4814 95.0842L60.1885 94.3771C61.2153 93.3503 62.57 92.1101 64.1408 90.672C64.9814 89.9025 65.8839 89.0763 66.8312 88.1957C69.527 85.6899 72.5393 82.7853 75.3569 79.6303C80.9432 73.3748 85.9628 65.9222 85.9628 58.4814C85.9628 43.8561 74.1067 32 59.4814 32C44.8561 32 33 43.8561 33 58.4814C33 65.9221 38.0153 73.3705 43.5997 79.624C46.4163 82.7781 49.428 85.6822 52.1246 88.1887C53.0707 89.0682 53.9723 89.8936 54.8124 90.6628C56.3861 92.1036 57.744 93.3468 58.7743 94.3771Z" stroke="white" stroke-width="2"/>
</g>
<path d="M61.8136 46.3779C62.498 45.5348 63.8337 46.22 63.5701 47.279L61.2506 56.5995H67.9415C68.8341 56.5995 69.3245 57.6533 68.7573 58.3528L57.188 72.6216C56.5039 73.4656 55.1674 72.78 55.4314 71.7206L57.7564 62.3897H51.0586C50.1657 62.3897 49.6754 61.3355 50.2429 60.636L61.8136 46.3779Z" fill="white"/>
<g filter="url(#filter1_f_1508_16628)">
<path d="M84.0538 58.9485C82.2987 71.7461 63.8702 87.9085 59.4825 92.7295C55.5335 87.0349 36.6662 74.367 34.9111 58.9485C34.9111 45.4384 45.9121 34.4863 59.4825 34.4863C73.0529 34.4863 84.0538 45.4384 84.0538 58.9485Z" fill="url(#paint0_linear_1508_16628)" fill-opacity="0.45"/>
<path d="M61.8136 48.3659C62.498 47.554 63.8337 48.2138 63.5701 49.2336L61.2506 58.2089H67.9415C68.8341 58.2089 69.3245 59.2236 68.7573 59.8972L57.188 73.6376C56.5039 74.4503 55.1674 73.7901 55.4314 72.77L57.7564 63.7846H51.0586C50.1657 63.7846 49.6754 62.7695 50.2429 62.0959L61.8136 48.3659Z" fill="url(#paint1_linear_1508_16628)" fill-opacity="0.45"/>
</g>
<g filter="url(#filter2_d_1508_16628)">
<path d="M61.8136 46.3779C62.498 45.5348 63.8337 46.22 63.5701 47.279L61.2506 56.5995H67.9415C68.8341 56.5995 69.3245 57.6533 68.7573 58.3528L57.188 72.6216C56.5039 73.4656 55.1674 72.78 55.4314 71.7206L57.7564 62.3897H51.0586C50.1657 62.3897 49.6754 61.3355 50.2429 60.636L61.8136 46.3779Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1508_16628" x="0" y="0" width="118.963" height="129.498" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="16"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.596298 0 0 0 0 0.745319 0 0 0 0 0.885026 0 0 0 0.45 0"/>
<feBlend mode="plus-lighter" in2="BackgroundImageFix" result="effect1_dropShadow_1508_16628"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1508_16628" result="shape"/>
</filter>
<filter id="filter1_f_1508_16628" x="30.9111" y="30.4863" width="57.1426" height="66.2432" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1508_16628"/>
</filter>
<filter id="filter2_d_1508_16628" x="34" y="30" width="51" height="59" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.422703 0 0 0 0 0.812891 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1508_16628"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1508_16628" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1508_16628" x1="43.128" y1="81.1847" x2="83.9965" y2="63.4755" gradientUnits="userSpaceOnUse">
<stop offset="0.055" stop-color="#1D3EE9"/>
<stop offset="0.401106" stop-color="#00D1FF"/>
<stop offset="0.69" stop-color="#1F2C9F"/>
<stop offset="1" stop-color="#00C2FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1508_16628" x1="53.1769" y1="68.8483" x2="69.6276" y2="62.6743" gradientUnits="userSpaceOnUse">
<stop offset="0.055" stop-color="#1D3EE9"/>
<stop offset="0.401106" stop-color="#00D1FF"/>
<stop offset="0.69" stop-color="#1F2C9F"/>
<stop offset="1" stop-color="#00C2FF"/>
</linearGradient>
</defs>
</svg>`;
