import { computed, Injectable, signal } from '@angular/core';
import { chargePointDetails, IChargePointv2 } from '@pages/map/interfaces/chargepoints';

type mapUIParams = {
  latitude: string;
  longitude: string;
  radius: number;
  zoom: number;
};

// starting position map to customer maybe?
export const InitialMapPosition: mapUIParams = {
  latitude: '59.165692143635496',
  longitude: '17.61991628465645',
  radius: 400,
  zoom: 8,
};

@Injectable()
export class MapUiStateService {
  isFilterMenuVisible = signal<boolean>(true);
  currentMarkers: H.map.Marker[] = [];
  hoverFeatureAvailable: boolean = true;

  mapUiParams = InitialMapPosition;

  renderMap = signal<boolean>(false);

  // Tapped CPO
  selectedChargePoint = signal<IChargePointv2>(undefined as any);
  selectedChargePointDetails = signal<chargePointDetails>(undefined as any);
  selectedChargePointdetailsLoading = signal<boolean>(false);

  // hover CPO
  hoveredChargePoint = signal<IChargePointv2 | undefined>(undefined as any);

  showChargePointDetails = signal<boolean>(false);

  showFilterMenu() {
    this.isFilterMenuVisible.set(true);
  }

  hideFilterMenu() {
    this.isFilterMenuVisible.set(false);
  }
}
