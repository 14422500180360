import { Injectable } from '@angular/core';

const InitialMapPosition = {
  lat: '60.60395',
  long: '17.76278',
  radius: 400,
};

@Injectable()
export class HereMapsUiControlsService {
  mapInstance: H.Map;
  mapCenter: H.geo.Point;
  mapUI: H.ui.UI;
  boundingBox: H.geo.Rect | null | undefined;
  latitude: string = InitialMapPosition.lat;
  longitude: string = InitialMapPosition.long;
  radius: number = InitialMapPosition.radius;
  zoomLevel: number;
  behaviour: H.mapevents.Behavior;

  reCreateCluster: boolean = true;

  previousPosition: {
    center: H.geo.IPoint;
    zoom: number;
  };

  useClusterEndPoint: boolean;
  clusterLayer: H.map.layer.ObjectLayer | undefined;
  apiKey: 'wyBnr85mHwleRwtLqvj5fwZ9_g9JnCovZMinW7FxHhs'; // where to store or get this instead of static text
  constructor() {
    // what should be default map position??
  }

  setMapInstance(map: H.Map) {
    this.mapInstance = map;
    this.mapUI = new H.ui.UI(this.mapInstance);
  }

  updateMapParams() {
    this.mapCenter = this.mapInstance.getCenter();
    this.latitude = this.mapCenter.lat.toString();
    this.longitude = this.mapCenter.lng.toString();
    this.zoomLevel = this.mapInstance.getZoom();
    this.boundingBox = this.mapInstance.getViewModel().getLookAtData().bounds?.getBoundingBox();

    console.log('Here Maps Zoom Level', this.zoomLevel);

    this.useClusterEndPoint = false;
    this.radius = this.calculateRadius();
  }

  calculateRadius(): number {
    let radiusInKM = this.radius;
    if (this.boundingBox) {
      var northWest = this.boundingBox.getTopLeft();
      var southEast = this.boundingBox.getBottomRight();

      // Calculate the distance from the center to the northwest corner (diagonal)
      var distanceToNorthWest = this.mapCenter.distance(northWest);
      var distanceToSouthEast = this.mapCenter.distance(southEast);
      // Get the northwest and southeast corners of the bounding box

      // The radius will be half of the diagonal distance
      var radius = Math.max(distanceToNorthWest, distanceToSouthEast);
      radiusInKM = Math.floor(radius / 1000);
      console.log('Radius', radiusInKM, 'KM');
    }

    return radiusInKM < 1 ? 1 : radiusInKM;
  }

  setUIControls(layers: Object) {
    // add UI controls
    let ui = H.ui.UI.createDefault(this.mapInstance, layers);

    this.setZoomControls(ui);
    //remove settings from UI
    const mapSettingsControl = ui.getControl('mapsettings');
    mapSettingsControl?.setVisibility(false);
  }

  setZoomControls(ui: H.ui.UI) {
    const zoomControl = ui.getControl('zoom');
    zoomControl?.addClass('custom-zoom-controls');

    if (zoomControl) {
      const zoomInButton = zoomControl.getElement()?.firstChild;
      const zoomOutButton = zoomControl.getElement()?.firstChild?.nextSibling;

      zoomInButton?.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        const currentZoom = this.mapUI.getMap().getZoom();
        this.mapUI.getMap().setZoom(currentZoom + 1, true); // Zoom IN by one level
        this.behaviour.enable();
      });
      zoomOutButton?.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        const currentZoom = this.mapUI.getMap().getZoom();
        this.mapUI.getMap().setZoom(currentZoom - 1, true); // Zoom Out by one level
      });
    }
  }

  setMapBehaviors() {
    const mapEvents = new H.mapevents.MapEvents(this.mapInstance);
    // Add behavior to the map: panning, zooming, etc.
    this.behaviour = new H.mapevents.Behavior(mapEvents);

    const FRACTIONAL_ZOOM = H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM;
    this.behaviour.disable(FRACTIONAL_ZOOM);
  }

  navigateToPoint(center: H.geo.IPoint, zoom: number) {
    this.savePreviousPosition();
    this.mapInstance.getViewModel().setLookAtData(
      {
        position: center,
        zoom: zoom,
      },
      10,
    );
  }

  savePreviousPosition() {
    const center = this.mapInstance.getViewModel().getLookAtData().position!;
    const zoom = this.mapInstance.getViewModel().getLookAtData().zoom!;

    this.previousPosition = {
      center: center,
      zoom: zoom,
    };
  }

  navigateToPreviousPosition() {
    this.navigateToPoint(this.previousPosition.center, this.previousPosition.zoom);
  }
}
