import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject, signal } from '@angular/core';
import { DatadogMetricLoggerService } from '@core/services/datadog-metric-logger.service';
import { environment } from '@environments/environment';
import { ClusteredChargePoints } from '@pages/map/interfaces/chargePointClustering';
import { chargePointDetails, IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { ChargePointApiService } from '@pages/map/services/charge-point-api.service';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';
import { MarkerService } from '@pages/map/services/marker.service';
import { first, tap } from 'rxjs';

export const InitialMapPosition = {
  lat: '59.165692143635496',
  long: '17.61991628465645',
  radius: 400,
};

@Injectable()
export class ChargePointService {
  private readonly chargePointsURL = environment.chargepoint;
  private queryParams: string = '';
  private httpClient = inject(HttpClient);
  private datadogMetricLogger = inject(DatadogMetricLoggerService);
  private markerService = inject(MarkerService);
  private chargePointAPI = inject(ChargePointApiService);
  private mapUIControls = inject(HereMapsUiControlsService);

  private mapUIState = inject(MapUiStateService);

  chargePoints = signal<IChargePointv2[]>([]);

  minPower = this.chargePointAPI.minPower;
  showPassengerCarChargingPoints = this.chargePointAPI.showPassengerCarChargingPoints;
  showLimitedTruckChargingPoints = this.chargePointAPI.showLimitedTruckChargingPoints;

  initialEffectRun = true;
  constructor() {
    effect(() => {
      console.log('effect call');
      if (this.initialEffectRun) {
        this.registerEffects();
        this.initialEffectRun = false;
      } else {
        this.mapUIControls.reCreateCluster = true;
        this.getChargePoints();
        // this.getClusteredChargePoints()
      }
    });
  }

  registerEffects() {
    this.showLimitedTruckChargingPoints();
    this.showPassengerCarChargingPoints();
    this.minPower();
  }

  setMinPowerFilter(value: number) {
    this.minPower.set(value);
  }

  setMaxPowerFilter(value: number) {
    this.minPower.set(value);
  }

  setCarChargingFilter(value: boolean) {
    this.showPassengerCarChargingPoints.set(value);
  }

  setLimitedTruckChargePoints(value: boolean) {
    this.showLimitedTruckChargingPoints.set(value);
  }

  // setTruckBuiltFilter(value: boolean) {}

  getClusteredChargePoints() {
    this.chargePointAPI
      .getClusteredChargePoints()
      .pipe(first())
      .subscribe({
        next: (chargePoints: ClusteredChargePoints[]) => {
          this.markerService.setMarkerRenderingLogic(chargePoints);
        },
        error: () => {},
        complete: () => {},
      });
  }

  mapViewChanged() {
    if (this.mapUIControls.useClusterEndPoint) {
      //temp demo
      console.log('using clusterEndPoint');
      this.getClusteredChargePoints();
    } else {
      console.log('Using list Endpoint');
      this.getChargePoints();
    }
  }

  getChargePoints() {
    if (this.mapUIControls.reCreateCluster) {
      this.chargePointAPI
        .getChargePointsList()
        .pipe(
          first(),
          tap((res) => {
            this.datadogMetricLogger.chargePointApiPerformance(
              res.length,
              this.mapUIControls.latitude,
              this.mapUIControls.longitude,
              this.mapUIControls.radius,
            );
          }),
        )
        .subscribe({
          next: (chargePoints: IChargePointv2[]) => {
            this.chargePoints.set(chargePoints);
            this.markerService.setMarkerRenderingLogic(chargePoints);
          },
          error: () => {},
          complete: () => {},
        });
    }
  }

  getChargePointDetail(chargePointId: string) {
    return this.chargePointAPI.getChargePointDetail(chargePointId);
  }
}
